* {
  box-sizing: border-box;
}

img {
  height: 100%;
  width: 100%;
  /* scale: 100%; */
  vertical-align: middle;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.pageImage {
  width: 50%;
}

/* .bioImages {
  max-width: 75%;
} */

.bioText {
  padding-top: 15px;
  padding-bottom: 15px;
  /* max-width: 75%; */
}

.comparison-slider {
  margin: 50px auto;
  /* max-width: 800px; */
  transform: translateY(-10%) scale(0.75);
  position: relative;
  /* overflow: hidden; */
  box-shadow: 0 15px 15px rgb(0 0 0 / 25%);
  /* scale: 50%; */
}

.comparison-item {
  overflow: hidden;
}

.comparison-item.top {
  z-index: 1;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.comparison-item.top img {
  height: 100%;
  object-fit: cover;
}

.handle {
  position: absolute;
  width: 3px;
  height: 100%;
  background: white;
  z-index: 3;
  cursor: col-resize;
  top: 0;
}

.handle svg {
  display: block;
  position: absolute;
  top: calc(50% - 15px);
  right: calc(50% - 15px);
  height: 30px;
  width: 30px;
  background: white;
  border-radius: 8px;
  padding: 2px;
}

/* DEMO ONLY */
.photo-creds {
  position: absolute;
  text-align: right;
  right: 10px;
  bottom: 10px;
}

.photo-creds a {
  display: block;
}
